<template>
    <div>
        <div class="relative z-0 w-full group">
            <label :for="inputName+'_'+SecondId+'_'+TargetId"
                   class="w-full text-sm peer-focus:font-medium absolute text-gray-500 top-0
                        peer-focus:left-0 peer-focus:text-blue-600
                        "
            >
                {{ label }}
            </label>
            <input
                v-model="input_value"
                type="text"
                :name="inputName"
                :id="inputName+'_'+SecondId+'_'+TargetId"
                :class="['block w-full px-0 mt-2 pt-5 border-0 border-b-2 border-gray-300 appearance-none peer',
                      'focus:outline-none focus:ring-0 peer',textAligne,
                       (readOnly)?'bg-gray-100':'bg-transparent focus:border-blue-600',
                       (onUpdate)?'border-green-600 shadow-green-500/50':''
                      ]"
                :readonly="readOnly"
                :autocomplete="autoComplete"
                @focusout="handleField"
            />
        </div>
    </div>
</template>

<script setup>

    import {ref, watch} from "vue";

    const props = defineProps({
        inputValue:{type: [String, Number], required: false,default:'' },
        label:{type: String, required: false },
        inputName:{type: String, required: false },
        TargetId:{type:Number, required: false, default:0},
        SecondId:{type:Number, required: false, default:0},
        readOnly:{type:Boolean, required: false,default:false},
        autoReset:{type:Boolean, required: false,default:false},
        autoComplete:{type: String, required: false,default:'off'},
        textAligne:{type: String, required: false,default:'text-left'},
    });

    const input_value=ref(props.inputValue)
    const onUpdate=ref(false)

    const emit = defineEmits(['returnValue'])
    function handleField(){
        if(props.readOnly===false) {
            onUpdate.value = true;
            emit('returnValue', {
                value: input_value.value,
                name: props.inputName,
                id: props.TargetId
            });
            if (props.autoReset) {
                input_value.value = ''
            }
        }

    }

    watch(() => props.inputValue, (newVal) => {
        input_value.value = newVal || '';
        onUpdate.value=false;
    }, { immediate: true });

</script>
